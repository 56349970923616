import MDinput from '@/components/MDinput';
import { validatorRequire } from '@/utils/validators';
import NumericInput from '@/components/DSE/NumericInput';

export function getFormDefinition(vue) {
  return {
    code: {
      type: MDinput,
      defaultValue: '',
      props: {
        caption: 'language.code',
        maxlength: 2,
        textTransform: 'lowercase',
        required: true
      },
      rules: [
        {
          validator: validatorRequire
        }
      ]
    },
    description: {
      type: MDinput,
      defaultValue: '',
      props: {
        maxlength: 255,
        required: true
      },
      rules: [
        {
          validator: validatorRequire
        }
      ]
    },
    position: {
      type: NumericInput,
      defaultValue: 0,
      props: {
        type: 'number',
        precision: 0,
        caption: 'common.position'
      }
    }
  };
}
export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return item;
}
